import React, { useState } from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'

import PageTitle from '../components/PageTitle'

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Dados enviados:', formData)
    alert('Mensagem enviada com sucesso!')
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <Container>
      <PageTitle title="Contato" />
      <Row className="justify-content-md-center suse">
        <Col md={6} className='padding-50'>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '20px' }}>
              <Row className='text-center' style={{ padding: '20px', border: '3px solid red', borderRadius: '5px' }}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h3>Trabalhe com a gente</h3>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div style={{ display: 'block' }}>Envie seu currículo para o e-mail</div>
                  <div>rhpostoselefantinho@gmail.com</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={6} className='padding-50'>
          Entre em contato através dos seguintes canais ou mandando uma mensagem pelo formulário abaixo.
          <br /><br />
          E-mail:
          <br />
          contato@postoselefantinho.com.br
          <br /><br />
          Telefone:
          <br />
          (14) 3226-4506
          <Form onSubmit={handleSubmit} style={{ marginTop: '50px' }}>
            <Form.Group controlId="formName" style={{marginBottom: '10px' }}>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Seu nome"
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail" style={{marginBottom: '10px' }}>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Seu e-mail"
                required
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Mensagem</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Sua mensagem"
                required
              />
            </Form.Group>
            <Button variant="danger" type="submit" className="mt-3">
              Enviar
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
