import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SocialIcons from './SocialIcons';
import logo from '../assets/images/logo_elefantinho.png';

function Footer() {
    return (
        <footer className="footer">
            <Container style={{ background: '#CCC', padding: '30px' }}>
                <Row className="align-items-center">
                    <Col md={6} className="d-flex align-items-center">
                        <img
                            src={logo}
                            width="50"
                            height="50"
                            className="d-inline-block align-top"
                            alt="Logo"
                        />
                    </Col>
                    <Col md={6} className="d-flex justify-content-end">
                        <SocialIcons />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr className="footer-divider" />
                        <p className="text-center mb-0 suse-500">© {new Date().getFullYear()} Postos Elefantinho. Todos os direitos reservados.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;