import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Slider from '../components/Slider';
import Highlights from '../components/Highlights';
import Images from '../components/Images';
import backgroundImage from '../assets/images/posto_01.png';
import AnalyticsService from '../services/AnalyticsService';

function Home() {
    useEffect(() => {
        const params = {
            hitType: 'pageview',
            page: '/',
            title: 'Página inicial'
        }
        AnalyticsService.send(params);
    })

    return (
        <>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Slider />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className='highlights-background' style={{ backgroundImage: `url(${backgroundImage})`}}>
                <Highlights />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Images />
            </Col>
        </>
    );
}

export default Home;