import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'
import InDevelopment from './pages/InDevelopment'
import Header from './components/Header'
import Home from './pages/Home'
import About from './pages/About'
import Stores from './pages/Stores'
import Services from './pages/Services'
import Contact from './pages/Contact'
import Footer from './components/Footer'
import AnalyticsService from './services/AnalyticsService'
import './App.css'

function App() {
  const routes = [
    {
      path: '/',
      component: <Home />
    },
    {
      path: '/sobre',
      component: <About />
    },
    {
      path: '/servicos',
      component: <Services />
    },
    {
      path: '/lojas',
      component: <Stores />
    },
    {
      path: '/contato',
      component: <Contact />
    }
  ]

  useEffect(() => {
    AnalyticsService.initialize();
  })

  return (
    <Router>
      <Routes>
        <Route element={<DefaultLayout />}>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.component} />
          ))}
        </Route>
      </Routes>
    </Router>
  );
}

function DefaultLayout() {
  return (
    <>
      <Header />
        <main>
          <Outlet />
        </main>
      <Footer />
    </>
  )
}

export default App;
