import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FaGasPump } from "react-icons/fa"
import { FaPeopleGroup } from "react-icons/fa6"
import { GiCoffeeCup } from "react-icons/gi"

function Highlights() {
    const elements = [
        {
            text: 'Combustível de qualidade é um dos principais pilares para o bom desempenho e a longevidade dos veículos, pois tem um papel crucial na otimização do funcionamento do motor e na preservação de seus componentes internos.',
            icon: <FaGasPump className='icon-gas-station'/>
        },
        {
            text: 'Contamos com uma equipe treinada e dedicada a oferecer um atendimento especializado e personalizado, garantindo que cada visita seja rápida, eficiente e segura. Seja para abastecer seu veículo ou esclarecer dúvidas, você pode confiar que nossa equipe está aqui para proporcionar o melhor serviço possível, com a atenção e o cuidado que você e seu carro merecem.',
            icon: <FaPeopleGroup className='icon-gas-station'/>
        },
        {
            text: 'Somos o seu ponto de parada ideal para uma pausa revigorante no seu dia. Aproveite nosso espaço para relaxar, saborear um café fresquinho e um pão de queijo quentinho. Seja para um rápido lanche ou um momento de descanso, Venha nos visitar e descubra como transformar uma simples parada em um prazeroso intervalo.',
            icon: <GiCoffeeCup className='icon-gas-station'/>
        }
    ];

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Container>
                        <Row className='highlights-row'>
                            {elements.map((element, index) => (
                                <Col key={index} xs={12} sm={12} md={12} lg={4}>
                                    <Row className='h-100 highlights-row-internal'>
                                        <Col xs={12} sm={12} md={12} lg={12} className='highlights-col d-flex flex-column justify-content-center align-items-center'>
                                            <div>
                                                <span className='suse highlights-text'>
                                                    { element.text }
                                                </span>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <span>
                                                    { element.icon }
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default Highlights