import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Carousel, Card, Badge, Dropdown } from 'react-bootstrap'

import PageTitle from '../components/PageTitle'

import { FaLocationDot, FaStore } from "react-icons/fa6"

function Stores() {

    const [stores, setStores] = useState([]);
    const [uniqueCities, setUniqueCities] = useState([]);
    const [showStores, setShowStores] = useState([])

    const fetchData = () => {
        const responseApi = [
            {
                title: 'Elefantinho Premium Comendador',
                city: 'Bauru',
                address: 'Av. Comendador José da Silva Martha, 1122 - Jardim Estoril',
                maps: 'Av. Comendador José da Silva Martha, 1122 - Jardim Estoril, Bauru - SP, 17016-080',
                path: 'elefantinho_premium_comendador',
                stores: [
                    {
                        name: 'Padaria Copacabana Premium',
                        instagram: 'padariacopacabanapremium'
                    },
                    {
                        name: 'Farmácia Multi Drogas',
                        instagram: 'multicomendador'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_premium_comendador_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_premium_comendador_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_premium_comendador_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho Premium Nações',
                city: 'Bauru',
                address: 'Av. Nações Unidas, 05-50 - Nucleo Res. Pres. Geisel',
                maps: 'Av. Nações Unidas, 05-50 - Nucleo Res. Pres. Geisel, Bauru - SP, 17033-040',
                path: 'elefantinho_premium_nacoes',
                stores: [
                    {
                        name: 'Conveniência Beer Premium Elefantinho'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_premium_nacoes_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_premium_nacoes_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_premium_nacoes_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho Pit Stop',
                city: 'Bauru',
                address: 'Av. Rodrigues Alves, 1970 - Vila Cardia',
                maps: 'Av. Rodrigues Alves, 1970 - Vila Cardia, Bauru - SP, 17013-242',
                path: 'elefantinho_rodrigues_I',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_rodrigues_I_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_rodrigues_I_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_rodrigues_I_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho Posto 7',
                city: 'Bauru',
                address: 'Av. Rodrigues Alves, Quadra 22 - Vila Cardia',
                maps: 'Av. Rodrigues Alves, Quadra 22 - Vila Cardia, Bauru - SP, 17013-242',
                path: 'elefantinho_rodrigues_II',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_rodrigues_II_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_rodrigues_II_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_rodrigues_II_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho Vista Alegre',
                city: 'Bauru',
                address: 'Alameda Cônego Aníbal Difrância, Quadra 4 - Parque Alto Sumare',
                maps: 'Alameda Cônego Aníbal Difrância, Quadra 4 - Parque Alto Sumare, Bauru - SP, 17020-690',
                path: 'elefantinho_vista_alegre',
                stores: [
                    {
                        name: 'Miau Estética Animal',
                        instagram: 'miau.esteticaanimal'
                    },
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_vista_alegre_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_vista_alegre_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_vista_alegre_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho Centro Automotivo',
                city: 'Bauru',
                address: 'Av. Moussa Nakhi Tobias, n° 2-33 Pq. - São Geraldo',
                maps: 'Av. Moussa Nakhi Tobias, n° 2-33 Pq. - São Geraldo – Bauru – Sp -  Cep: 17021-005',
                path: 'elefantinho_sao_geraldo',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_sao_geraldo_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_sao_geraldo_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_sao_geraldo_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho Chapadão',
                city: 'Bauru',
                address: 'Rua: Cezar Cruz Ciafrei, 425 - Jd. Mendonça',
                maps: 'Rua: Cezar Cruz Ciafrei, 425 – J Mendonça – Bauru – Sp – Cep: 17027-050',
                path: 'elefantinho_chapadao',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_chapadao_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_chapadao_2.png'
                    }
                ]
            },
            {
                title: 'Elefantinho Lençóis Paulista',
                city: 'Lençóis Paulista',
                address: 'R. Olávo Bilac, 210 - Vila Nova Irere',
                maps: 'R. Olávo Bilac, 210 - Vila Nova Irere, Lençóis Paulista - SP, 18682-210',
                path: 'elefantinho_lencois',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_lencois_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_lencois_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_lencois_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho I',
                city: 'Agudos',
                address: 'R. Sete de Setembro, 1751 - Vila Prof. Simoes',
                maps: 'R. Sete de Setembro, 1751 - Vila Prof. Simoes, Agudos - SP, 17120-000',
                path: 'elefantinho_agudos_I',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_agudos_I_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_agudos_I_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_agudos_I_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho II',
                city: 'Agudos',
                address: 'Av. Fernando Machado',
                maps: 'Av. Fernando Machado - Agudos, SP, 17120-000',
                path: 'elefantinho_agudos_II',
                stores: [
                    {
                        name: 'Bendito Japa Pub',
                        instagram: 'bendito.japapuboficial'
                    },
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_agudos_II_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_agudos_II_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_agudos_II_3.png'
                    }
                ]
            },
            {
                title: 'Pampulha',
                city: 'Agudos',
                address: 'Acesso Richard Frendenberg',
                maps: 'Acesso Richard Frendenberg n/a - Agudos/SP - Cep: 17132-098',
                path: 'elefantinho_pampulha',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_pampulha_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_pampulha_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_pampulha_3.png'
                    }
                ]
            },
            {
                title: 'Esmeralda',
                city: 'Agudos',
                address: 'Rua: José Nogueira de Abreu, 15 - V.Honorina',
                maps: 'Rua: José Nogueira de Abreu, 15 – V.Honorina – Agudos – Sp – Cep: 17123-001',
                path: 'elefantinho_esmeralda',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_esmeralda_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_esmeralda_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_esmeralda_3.png'
                    }
                ]
            },
            {
                title: 'Elefantinho Garça',
                city: 'Garça',
                address: 'Av. Labiano Costa Machado,1.641 - Centro',
                maps: 'Av. Labiano Costa Machado,1.641 – Centro – Garça – Sp – Cep:17400-000',
                path: 'elefantinho_garca',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_garca_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_garca_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_garca_3.png'
                    }
                ]
            },
            {
                title: 'Eskinão I',
                city: 'Marília',
                address: 'R. São Luiz, 1674 - Centro',
                maps: 'R. São Luiz, 1674 - Centro, Marília - SP, 17500-002',
                path: 'elefantinho_marilia_eskinao_I',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_I_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_I_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_I_3.png'
                    }
                ]
            },
            {
                title: 'Eskinão II',
                city: 'Marília',
                address: 'R. Onório Machado, 15 - Vila Coimbra',
                maps: 'R. Onório Machado, 15 - Vila Coimbra, Marília - SP, 17527-050',
                path: 'elefantinho_marilia_eskinao_II',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_II_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_II_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_II_3.png'
                    }
                ]
            },
            {
                title: 'Eskinão III',
                city: 'Marília',
                address: 'Jardim Monte Castelo',
                maps: 'Jardim Monte Castelo, Marília - SP, 17533-001',
                path: 'elefantinho_marilia_eskinao_III',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_III_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_III_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_III_3.png'
                    }
                ]
            },
            {
                title: 'Eskinão IV',
                city: 'Marília',
                address: 'Av. República, 2227 - Palmital',
                maps: 'Av. República, 2227 - Palmital, Marília - SP, 17510-402',
                path: 'elefantinho_marilia_eskinao_IV',
                stores: [
                    {
                        name: 'Conveniência 5 Minutos'
                    }
                ],
                images: [
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_IV_1.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_IV_2.png'
                    },
                    {
                        title: 'Teste',
                        name: 'elefantinho_marilia_eskinao_IV_3.png'
                    }
                ]
            }
        ];
        setStores(responseApi);
        const cities = Array.from(new Set(responseApi.map(item => item.city)));
        setUniqueCities(['Todas as cidades', ...cities]);
        setShowStores(responseApi);
    }

    function cityFilter(city) {
        let filtered = null
        if (city === 'Todas as cidades') {
            filtered = stores
        } else {
            filtered = stores.filter(item => item.city === city)
        }
        setShowStores(filtered)
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container>
            <PageTitle title="Nossas Unidades" />
            <Row style={{ marginBottom: '20px', textAlign: 'center' }}>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="danger" id="dropdown-basic">
                            Filtrar por cidade
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {uniqueCities?.map((city, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => cityFilter(city)}
                                >
                                    {city}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row style={{ padding: '0px', textAlign: 'center' }}>
                {showStores?.map((element, index) => (
                    <Col key={index} xs={12} sm={12} md={6} lg={{ span: 4, offset: index === 15 ? 2 : 0 }} style={{ marginBottom: '40px' }}>
                        <Card className='h-100'>
                            <Carousel indicators={false} className='slider'>
                                {element.images.map((image, index) => (
                                    <Carousel.Item key={index}>
                                        <img
                                            src={require(`../assets/images/unidades/${element.path}/${image.name}`)}
                                            alt={image.title}
                                            style={{
                                                width: '100%',
                                                height: '300px',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            <Card.Body className='suse'>
                                <Card.Title>
                                    <span className='suse-800'>
                                        { element.title }
                                    </span>
                                </Card.Title>
                                <Card.Text>
                                    <div>
                                        <Badge bg="danger">{ element.city }</Badge>
                                    </div>
                                    <div>
                                        <FaLocationDot /> { element.address }
                                    </div>
                                    {element.stores?.map((store, index) => (
                                        <div key={index}>
                                            { store.instagram ? (
                                                <>
                                                    <FaStore style={{ marginRight: '5px' }} />
                                                    <a href={'https://instagram.com/' + store.instagram} target='_blank' rel='noreferrer'>
                                                        {store.name}
                                                    </a>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <FaStore /> {store.name}
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Stores;