import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import SocialIcons from './SocialIcons';

function Menu() {
    const elements = [
        {
            name: 'HOME',
            path: '/'
        }, 
        {
            name: 'SOBRE',
            path: '/sobre'
        }, 
        {
            name: 'NOSSAS UNIDADES',
            path: '/lojas'
        },
        {
            name: 'O QUE TEM NA ELEFANTINHO',
            path: '/servicos'
        },
        {
            name: 'CONTATO',
            path: '/contato'
        }
    ];

    return (
        <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="menu">
                <Nav className="mx-auto suse-600">
                    {elements.map((element, index) => (
                        <Nav.Link key={index} href={element.path}>{element.name}</Nav.Link>
                    ))}
                </Nav>
                <SocialIcons />
            </Navbar.Collapse>
        </>
    );
}

export default Menu;
