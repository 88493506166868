import React from 'react'
import { Container, Image, Row, Col } from 'react-bootstrap'
import Image01 from '../assets/images/home/home_01.png'
import Image02 from '../assets/images/home/home_02.png'
import Image03 from '../assets/images/home/home_03.png'
import Image04 from '../assets/images/home/home_04.png'

function Images() {
    const elements = [
        {
            image: Image01,
            description: 'Combustível de qualidade nos Postos Elefantinho'
        },
        {
            image: Image02,
            description: 'Lojas de conveniência nos Postos Elefantinho'
        },
        {
            image: Image03,
            description: 'Café e salgados nos Postos Elefantinho'
        },
        {
            image: Image04,
            description: 'Café e salgados nos Postos Elefantinho'
        }
    ];

    return (
        <Container>
            <Row>
                {elements.map((element, index) => (
                    <Col key={index} className='text-center' xs={12} sm={12} md={6} lg={3} style={{ marginTop: '50px', marginBottom: '50px' }} >
                        <Image src={element.image} style={{ maxWidth: '100%', height: 'auto' }} alt={element.description} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Images;