import React from 'react'
import { Row, Col } from 'react-bootstrap'

function PageTitle({ title }) {
    return (
        <Row className="page-title text-center suse">
            <Col className='page-title-col' md={12}>
                <h1 className='suse-800'>{ title }</h1>
            </Col>
        </Row>
    );
}

export default PageTitle;
