import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLink } from 'react-icons/fa';

function SocialIcons() {
    const elements = [
        {
            url: 'https://www.facebook.com/profile.php?id=61555943349357',
            icon: <FaFacebookF />,
            description: 'Logo do Facebook'
        }, 
        {
            url: 'https://www.instagram.com/postos_elefantinho',
            icon: <FaInstagram />,
            description: 'Logo do Instagram'
        }, 
        {
            url: 'https://linktr.ee/postoselefantinho',
            icon: <FaLink />,
            description: 'Logo do Linktree'
        }
    ];

    return (
        <Row>
            {elements.map((element, index) => (
                <Col key={index} className='text-center social-icons'>
                    <a href={element.url} target='_blank' rel='noreferrer' aria-label={elements.description}>
                        {element.icon}
                    </a>
                </Col>
            ))}
        </Row>
    );
}

export default SocialIcons;
