import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';

function Slider() {
    const elements = [
        {
            name: 'banner_01.webp',
            title: 'Banner 01'
        }, 
        {
            name: 'banner_02.webp',
            title: 'Banner 02'
        }, 
        {
            name: 'banner_03.webp',
            title: 'Banner 03'
        },
        {
            name: 'banner_04.webp',
            title: 'Banner 04'
        }
    ];

    return (
        <Container>
            <Row>
                <Col>
                    <Carousel indicators={false} className='slider'>
                        {elements.map((element, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    src={require(`../assets/images/banners/${element.name}`)}
                                    alt={element.title}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
}

export default Slider;
