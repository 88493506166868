import React, { useEffect } from 'react'
import { Container, Row, Col, Figure } from 'react-bootstrap'
import { BsStar, BsRocketTakeoff, BsEye } from 'react-icons/bs'
import { SlBookOpen } from 'react-icons/sl'

import AnalyticsService from '../services/AnalyticsService'

import PageTitle from '../components/PageTitle'

import PhotoPrimeiroPostoShell from '../assets/images/posto_elefantinho_rodrigues_01.jpg'
import PhotoElefantinhoAntigo from '../assets/images/elefantinho_antigo.jpeg'
import PhotoCaminhaoElefantinho from '../assets/images/caminhao_elefantinho.jpeg'
import PhotoPostoAtual from '../assets/images/posto_elefantinho_atual.jpeg'

function About() {
    const content = {
        title: 'Nossa História',
        icon: <SlBookOpen style={{ fontSize: '35px', marginBottom: '10px', color: 'red' }} size={40} />,
        text1: 'Em 1984, o Sr. Davilço, nascido na cidade de Uru - SP, juntamente com seu pai, Orlando, com objetivo de proporcionar melhorias em sua cidade natal, compraram um terreno e construíram o posto pioneiro da rede, que sustentava a bandeira Shell.<br /><br />Nessa época o Sr. Davilço trabalhava nas Lojas Americanas como gerente, por este motivo passou a administração do posto para seu irmão Gerson. Em 1987, juntamente com um sócio, o até então gerente das Lojas Americanas adquiriu o Auto Posto Elefantinho de Bauru, situado na Av. Rodrigues Alves, que também sustentava bandeira Shell.',
        image1: PhotoPrimeiroPostoShell,
        imageCaption1: 'Posto da Av. Rodrigues Alves',
        text2: 'A partir dessa aquisição, o Sr. Davilço desligou-se das Lojas Americanas, para administrar o posto.<br /><br />Nessa época, o mercado de combustíveis era rigorosamente regulamentado pelo Governo Federal, através do D.N.C – Departamento Nacional de Combustíveis – que não permitia postos independentes com bandeira própria, obrigando a todos a ostentarem uma bandeira Shell, Esso, Texaco, Atlantic, Ipiranga ou Petrobrás.<br /><br />Em 1989, adquiriram, em Reginópolis,  a terceira unidade; esta com bandeira Texaco.',
        image2: PhotoElefantinhoAntigo,
        imageCaption2: '',
        text3: 'A partir desse ano, os negócios cresceram, com a aquisição de um posto na cidade de Lins-SP e Agudos-SP e a construção de um em Bauru.<br /><br />Em 1996, o Governo Federal desregulamentou todo o setor de combustíveis, criando a A.N.P – Agência Nacional do Petróleo. Liberando totalmente o mercado, surgiram desde então, novas distribuidoras e também passou a ser permitido o uso de marca própria em postos independentes.<br /><br />À medida que os contratos de uso de marca foram vencendo, os postos deixavam de usar as marcas das grandes companhias e passavam a operar como bandeira branca, denominação dada aos postos que usam bandeira própria, como “Elefantinho”, e não bandeira de alguma grande companhia.',
        image3: PhotoCaminhaoElefantinho,
        imageCaption3: '',
        text4: 'Em 1999, é desfeita a sociedade iniciada em 1987, passando a sociedade a ser composta pelos irmãos Davilço, Wilson, Edson, Gerson e pelo Sr. Orlando.<br /><br />Foi em 1999 que todos os postos passaram a utilizar a marca própria “Elefantinho”.<br /><br />A Rede de Postos Elefantinho atua hoje em  Agudos, Bauru, Garça, Iacanga, Lençóis Paulista, Marília, Reginópolis e Uru  com 20 unidades, empregando diretamente aproximadamente 400 funcionários.<br /><br />Atualmente, o plano de expansão da Rede inclui uma nova unidade, para agregar na cidade de Bauru.',
        image4: PhotoPostoAtual,
        imageCaption4: '',
        whoWeAre: [
            {
                type: 'values',
                icon: <BsStar style={{ fontSize: '35px', marginBottom: '10px' }} />,
                title: 'Nossos Valores',
                text: 'No Postos Elefantinho, nossos valores são a base de tudo o que fazemos e refletem nosso compromisso com a excelência e a satisfação dos nossos clientes. Desde a nossa fundação em 1985, buscamos não apenas oferecer um serviço de qualidade, mas também construir relacionamentos duradouros e de confiança com cada pessoa que passa por nossos postos.'
            },
            {
                type: 'mission',
                icon: <BsRocketTakeoff style={{ fontSize: '35px', marginBottom: '10px' }} />,
                title: 'Nossa Missão',
                text: 'Nossa missão é ser a escolha preferida dos nossos clientes, oferecendo não apenas um serviço de qualidade, mas uma experiência que inspire confiança e fidelidade. No Postos Elefantinho, estamos empenhados em cumprir nossa missão com dedicação e paixão, sempre perto de você para atender suas necessidades com excelência.'
            },
            {
                type: 'vision',
                icon: <BsEye style={{ fontSize: '35px', marginBottom: '10px' }} />,
                title: 'Nossa Visão',
                text: 'No Postos Elefantinho, nossa visão é ser reconhecido como o líder em excelência e inovação no setor de combustíveis e serviços, transformando a experiência de nossos clientes e fortalecendo nossa presença nas comunidades em que atuamos.'
            }
        ]
    }

    useEffect(() => {
        const params = {
            hitType: 'pageview',
            page: '/sobre',
            title: 'Sobre'
        }
        AnalyticsService.send(params);
    })

    return (
        <>
            <Container fluid>
                <PageTitle title="Sobre os Postos Elefantinho" />
            </Container>
            <Container className='suse about'>
                <Row className='about-row'>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Col lg={12}>
                            { content.icon }
                        </Col>
                        <h2>{ content.title }</h2>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className='text-center about-col mobile-padding-0 padding-50'>
                        <Row className='padding-50 mobile-padding-25' style={{ border: '3px solid red', borderRadius: '5px' }}>
                            <Col xs={12} sm={12} md={6} lg={6} className='text-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: content.text1 }} />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={{ span: 6 }} className='text-center about-col mobile-padding-0 padding-50'>
                                <Figure>
                                    <Figure.Image
                                        className='image mobile-margin-top-50 mobile-margin-bottom-50 mobile-margin-top-0'
                                        alt={ content.imageCaption1 }
                                        src={ content.image1 }
                                        rounded
                                    />
                                    <Figure.Caption>
                                        { content.imageCaption1 }
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className='text-center about-col mobile-padding-0 padding-50'>
                        <Row className='padding-50 mobile-padding-25' style={{ border: '3px solid red', borderRadius: '5px' }}>
                            <Col xs={12} sm={12} md={12} lg={{ span: 6 }} className='text-center about-col mobile-padding-0 padding-50'>
                                <Figure>
                                    <Figure.Image
                                        className='image mobile-margin-top-50 mobile-margin-bottom-50 mobile-margin-top-0'
                                        alt={ content.imageCaption2 }
                                        src={ content.image2 }
                                        rounded
                                    />
                                    <Figure.Caption>
                                        { content.imageCaption2 }
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} className='text-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: content.text2 }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className='text-center about-col mobile-padding-0 padding-50'>
                        <Row className='padding-50 mobile-padding-25' style={{ border: '3px solid red', borderRadius: '5px' }}>
                            <Col xs={12} sm={12} md={6} lg={6} className='text-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: content.text3 }} />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={{ span: 6 }} className='text-center about-col mobile-padding-0 padding-50'>
                            <Figure>
                                    <Figure.Image
                                        className='image mobile-margin-top-50 mobile-margin-bottom-50 mobile-margin-top-0'
                                        alt={ content.imageCaption3 }
                                        src={ content.image3 }
                                        rounded
                                    />
                                    <Figure.Caption>
                                        { content.imageCaption3 }
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className='text-center about-col mobile-padding-0 padding-50'>
                        <Row className='padding-50 mobile-padding-25' style={{ border: '3px solid red', borderRadius: '5px' }}>
                            <Col xs={12} sm={12} md={12} lg={{ span: 6 }} className='text-center about-col mobile-padding-0 padding-50 mobile-margin-top-0'>
                                <Figure>
                                    <Figure.Image
                                        className='image mobile-margin-top-50 mobile-margin-bottom-50 mobile-margin-top-0'
                                        alt={ content.imageCaption4 }
                                        src={ content.image4 }
                                        rounded
                                    />
                                    <Figure.Caption>
                                        { content.imageCaption4 }
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} className='text-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: content.text4 }} />
                            </Col>
                        </Row>
                    </Col>
                    { content.whoWeAre.map((element,index) => (
                        <Col xs={12} sm={12} md={4} lg={4} className='text-center about-col mobile-padding-0 padding-50'>
                            <Container className='h-100' style={{ border: '3px solid red', borderRadius: '5px', display: 'flex', flexDirection: 'column' }}>
                                <Row style={{ background: 'red', padding: '20px', color: 'white' }}>
                                    <Col xs={12}>
                                        { element.icon }
                                    </Col>
                                    <Col xs={12}>
                                        <h3>{ element.title }</h3>
                                    </Col>
                                </Row>
                                <Row style={{ padding: '20px', flexGrow: 1 }}>
                                    <Col xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                        <div dangerouslySetInnerHTML={{ __html: element.text }} />
                                    </Col>
                                    <Col xs={12} style={{ display: 'none' }}>
                                        <ul>
                                            <li>
                                                1. Compromisso com a Qualidade
                                            </li>
                                            <li>
                                                2. Atendimento Personalizado
                                            </li>
                                            <li>
                                                3. Transparência e Honestidade
                                            </li>
                                            <li>
                                                4. Responsabilidade Social e Ambiental
                                            </li>
                                            <li>
                                                5. Inovação e Melhoria Contínua
                                            </li>
                                            <li>
                                                6. Respeito e Valorização
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
}

export default About;
