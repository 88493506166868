import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import PageTitle from '../components/PageTitle'

import ImageCalibragem from '../assets/images/services/calibragem.png'
import ImageCombustivel from '../assets/images/services/combustivel.png'
import ImageConveniencia from '../assets/images/services/conveniencia.png'
import ImageLavagemVidros from '../assets/images/services/lavagem_vidros.png'
import ImageTrocaOleo from '../assets/images/services/troca_de_oleo.png'

import { FaGasPump, FaStore, FaOilCan } from "react-icons/fa"
import { MdOutlineLocalCarWash } from "react-icons/md"
import { GiCarWheel } from "react-icons/gi";
import { AiOutlineSafetyCertificate } from "react-icons/ai"

function Services() {
    const elements = [
        {
            title: 'Combustível',
            text: 'No Postos Elefantinho, garantimos que sua experiência de abastecimento seja impecável. Oferecemos combustível de alta qualidade para que você possa dirigir com confiança e segurança. Em cada parada, você encontrará um atendimento cuidadoso e profissional, dedicado a você, ao seu carro e à sua família.',
            icon: <FaGasPump className='icon-gas-station'/>,
            image: ImageCombustivel
        },
        {
            title: 'Conveniências',
            text: 'Nossos postos são projetados para ser muito mais do que um simples ponto de abastecimento. Venha desfrutar de um café fresco com pão de queijo ou encontre a galera para um Happy Hour após o trabalho. Com uma estrutura completa e confortável, estamos aqui para tornar seus momentos de pausa ainda mais agradáveis.',
            icon: <FaStore className='icon-gas-station'/>,
            image: ImageConveniencia
        },
        {
            title: 'Troca de Óleo',
            text: 'Confiança é essencial quando se trata de troca de óleo, e no Postos Elefantinho, você pode contar com isso. Nossa equipe qualificada realiza a troca de óleo com precisão, seguindo todas as especificações do seu veículo para garantir segurança e performance.',
            icon: <FaOilCan className='icon-gas-station'/>,
            image: ImageTrocaOleo
        },
        {
            title: 'Lavagem dos Vidros',
            text: 'No Postos Elefantinho, nos dedicamos a oferecer um atendimento que vai além do esperado. Cuidamos do seu veículo com todo o zelo garantindo uma lavagem dos vidros que deixa seu carro impecável e você satisfeito.',
            icon: <MdOutlineLocalCarWash className='icon-gas-station'/>,
            image: ImageLavagemVidros
        },
        {
            title: 'Calibragem',
            text: 'Para a calibração dos seus pneus, conte com nossa equipe especializada. Garantimos que seu veículo esteja sempre em ótimas condições, pronto para rodar com segurança.',
            icon: <GiCarWheel className='icon-gas-station'/>,
            image: ImageCalibragem
        }
    ];

    return (
        <Container>
            <PageTitle title="Serviços" />
            <Row>
                {elements.map((element, index) => (
                    <Col md={{ span: 4, offset: index === 3 ? 2 : 0 }} style={{ marginBottom: '50px' }}>
                        <Card className='text-center h-100'>
                            <Card.Img variant="top" src={element.image} />
                            <Card.Body>
                                <Card.Title>
                                    <div style={{ padding: '20px' }}>
                                        {element.icon}
                                    </div>
                                    <div className='suse' style={{ marginBottom: '20px' }}>
                                        {element.title }
                                    </div>
                                </Card.Title>
                                <Card.Text className='suse'>
                                    { element.text }
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
                <Col xs={12} sm={12} md={12} lg={{ span: 8, offset: 2 }} style={{ marginBottom: '50px' }}>
                    <Card className='text-center h-100'>
                        <Card.Body>
                            <Card.Title style={{ marginTop: '20px' }}>
                                <div style={{ padding: '20px' }}>
                                    <AiOutlineSafetyCertificate className='icon-gas-station' />
                                </div>
                                <div className='suse' style={{ marginBottom: '20px' }}>
                                    Controle de Qualidade
                                </div>
                            </Card.Title>
                            <Card.Text className='suse' style={{ padding: '20px' }}>
                                Mesmo trabalhando com fornecedores de renome e confiança, na chegada do combustível ao posto, antes de efetuado o
                                descarregamento nos tanques, o gerente da unidade realiza junto ao motorista um minucioso teste para certificar que o
                                produto  encontra - se de acordo com as especificações da ANP, após essa verificação é coletado uma amostra testemunha
                                que fica  arquivada no posto, juntamente com uma cópia da nota fiscal e laudo de análise emitido pela distribuidora,
                                para clientes checarem sempre que quiserem.<br /><br />Temos ainda como procedimento padrão testes de qualidade e
                                aferições de bombas realizados três  vezes por semana, por funcionários altamente treinados, e podem ser realizados
                                sempre que algum cliente solicitar.<br /><br />Em todas as bombas  de etanol encontra -se densimetros, onde o cliente
                                tem em tempo real, um teste do combustível que está sendo abastecido, e também sistema de monitoramento contra vazamentos.
                                Além dessa segurança, adquirimos um aparelho profissional para monitoramento do PH e condutividade elétrica do etanol.
                                Hoje somos um dos poucos postos que possuem esse tipo de controle de qualidade do etanol, uma vês que trate - se de um
                                controle não exigido pela ANP e sim um procedimento que nós optamos por adotar,  para assegurar ainda mais qualidade
                                aos nosso clientes.<br /><br />Solicite o teste em qualquer um de nossos postos, e comprove a qualidade que a Rede
                                Elefantinho oferece.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Services