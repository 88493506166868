import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Menu from './Menu';

import logo from '../assets/images/logo_elefantinho.png';

function Header() {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/" className="d-flex align-items-center">
                    <img
                        src={logo}
                        width="50"
                        height="50"
                        className="d-inline-block align-top"
                        alt="Logo dos Postos Elefantinho"
                    />
                    <span className='suse-800 title'>Postos Elefantinho</span>
                </Navbar.Brand>
                <Menu />
            </Container>
        </Navbar>
    );
}

export default Header;